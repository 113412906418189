<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span>批阅管理/作业列表</span>
      </template>
    </HeaderBox>
    <p class="title">{{$route.query.homeworkTitle}}</p>
    <div class="dlex" v-if="listtype != 2">
      <div class="dlexitem">
        <span>姓名</span>
        <a-input
          class="nput"
          placeholder="请输入姓名"
          v-model="queryParams.userName"
        />
      </div>
      <div class="dlexitem">
        <span>手机号</span>
        <a-input
          class="nput"
          placeholder="请输入手机号"
          v-model="queryParams.mobile"
        />
      </div>
      <div class="dlexitem">
        <span>分数</span>
        <a-input-number
          class="nput"
          placeholder="最低分"
          v-model="queryParams.minScore"
        />
        ~ 
        <a-input-number
          class="nput"
          placeholder="最高分"
          v-model="queryParams.maxScore"
        />
      </div>
    </div>
    <div class="dlex" v-if="listtype != 2">
      <div class="dlexitem">
        <span>提交时间</span>
        <a-range-picker
          :show-time="{
            hideDisabledOptions: true,
            defaultValue: [
              moment('00:00:00', 'HH:mm:ss'),
              moment('11:59:59', 'HH:mm:ss'),
            ],
          }"
          format="YYYY-MM-DD HH:mm:ss"
          class="nput"
          @change="onChange"
        />
      </div>
      <div class="dlexitem">
        <span>批阅状态</span>
        <a-select
          mode="multiple"
          class="nput"
          placeholder="请选择批阅状态"
          v-model="queryParams.status"
        >
          <a-select-option :value="null"> 全部 </a-select-option>
          <a-select-option :value="1"> 暂存 </a-select-option>
          <a-select-option :value="0"> 未提交 </a-select-option>
          <a-select-option :value="3"> 已提交 </a-select-option>
          <a-select-option :value="5"> 已驳回 </a-select-option>
          <a-select-option :value="7"> 已审批 </a-select-option>
          <a-select-option :value="8"> 成绩发布</a-select-option>
        </a-select>
      </div>
      <div class="dlexitem">
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.answerId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="historyStatus" slot-scope="item, row">
          <div style="text-align: center">
            {{ statuslist[row.status] }}
          </div>
        </template>
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a
              @click="gohomeWorkDetail(item.answerId, 1)"
              v-if="item.status == 3"
              >批阅</a
            >
            <a @click="gohomeWorkDetail(item.answerId, 2)" v-else>查看作业</a>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "提交时间",
    align: "center",
    dataIndex: "submitTime",
  },
  {
    title: "提交次数",
    align: "center",
    dataIndex: "secondeflag",
  },
  {
    title: "批阅状态",
    align: "center",
    dataIndex: "historyStatus",
    scopedSlots: { customRender: "historyStatus" },
  },
  {
    title: "分数",
    align: "center",
    dataIndex: "score",
  },

  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      selectedKeys: "", //当前选择的
      loading: false, // 列表加载
      tableData: [], // 列表数据
      total: 0, // 总数
      // 作业状态
      statuslist: [
        "未提交",
        "暂存",
        "测试",
        "已提交",
        "测试",
        "已驳回",
        "已驳回",
        "已审批",
        "成绩发布",
      ],
      // 数据
      queryParams: {
        homeworkConfigId: null, //教师id
        MaxScore: null,
        MinScore: null,
        endTime: null,
        mobile: null,
        pageNum: 1,
        pageSize: 10,
        startTime: null,
        status: null,
        userName: null,
      },
      keyWord: "",
      columns,
      pageNum: 1, //页码
      pageSize: 10, // 页数
      listtype: null, // 判断是什么情况1看列表,2阅卷

      courseName: null, // 作业名称
    };
  },
  // 事件处理器
  methods: {
    moment,
    onChange(date, dateString) {
      console.log(date, dateString);
      this.queryParams.startTime = dateString[0];
      this.queryParams.endTime = dateString[1];
    },
    // 搜索
    search() {
      this.queryParams.pageNum = 1;
      this.getManageList();
    },
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.queryParams.pageNum = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.getManageList();
    },
    // 去阅卷
    gohomeWorkDetail(e, r) {
      this.$router.push(
        "/admin/Subjective/homeWorkDetail?id=" + e + "&type=" + r
      );
    },

    // 全场成绩提交
    publish() {
      this.buttonLoading = true;
      this.$ajax({
        method: "POST",
        url:
          "/hxclass-management/homeworkAnswer/grades/publish/all?config=" +
          this.queryParams.homeworkConfigId,
      }).then((res) => {
        this.buttonLoading = false;
        if (res.code == 200 && res.success) {
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 作业配置以及批改情况
    getConfigData() {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/homeworkAnswer/result/statistics",
        params: {
          homeworkConfigId: this.queryParams.homeworkConfigId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询列表
    getManageList() {
      this.$ajax({
        method: "post",
        url: "/hxclass-management/homeworkAnswer/select/student/read",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          // 获取作业名称
          if (res.data.records.length) {
            this.courseName = res.data.records[0].courseName;
          }
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.queryParams.homeworkConfigId = this.$AES.decode_data(
      this.$route.query.id
    );
    this.listtype = this.$route.query.type;
    if (this.listtype == 2) {
      this.queryParams.status = [3];
    }
    // this.getConfigData();
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-top: 20px;
  line-height: 19px;
}
.dlex {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dlexitem {
    width: 26%;
    display: flex;
    align-items: center;
    > span {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      display: block;
      flex-shrink: 0;
      width: 80px;
    }
    .nput {
      width: 240px;
      // height: 44px;
      border-radius: 3px 3px 3px 3px;
    }
  }
}
.dlex:nth-child(3) {
  margin-bottom: 24px;
  margin-top: 16px;
}
</style>