var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("批阅管理/作业列表")])]},proxy:true}])}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$route.query.homeworkTitle))]),(_vm.listtype != 2)?_c('div',{staticClass:"dlex"},[_c('div',{staticClass:"dlexitem"},[_c('span',[_vm._v("姓名")]),_c('a-input',{staticClass:"nput",attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.queryParams.userName),callback:function ($$v) {_vm.$set(_vm.queryParams, "userName", $$v)},expression:"queryParams.userName"}})],1),_c('div',{staticClass:"dlexitem"},[_c('span',[_vm._v("手机号")]),_c('a-input',{staticClass:"nput",attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.queryParams.mobile),callback:function ($$v) {_vm.$set(_vm.queryParams, "mobile", $$v)},expression:"queryParams.mobile"}})],1),_c('div',{staticClass:"dlexitem"},[_c('span',[_vm._v("分数")]),_c('a-input-number',{staticClass:"nput",attrs:{"placeholder":"最低分"},model:{value:(_vm.queryParams.minScore),callback:function ($$v) {_vm.$set(_vm.queryParams, "minScore", $$v)},expression:"queryParams.minScore"}}),_vm._v(" ~ "),_c('a-input-number',{staticClass:"nput",attrs:{"placeholder":"最高分"},model:{value:(_vm.queryParams.maxScore),callback:function ($$v) {_vm.$set(_vm.queryParams, "maxScore", $$v)},expression:"queryParams.maxScore"}})],1)]):_vm._e(),(_vm.listtype != 2)?_c('div',{staticClass:"dlex"},[_c('div',{staticClass:"dlexitem"},[_c('span',[_vm._v("提交时间")]),_c('a-range-picker',{staticClass:"nput",attrs:{"show-time":{
          hideDisabledOptions: true,
          defaultValue: [
            _vm.moment('00:00:00', 'HH:mm:ss'),
            _vm.moment('11:59:59', 'HH:mm:ss') ],
        },"format":"YYYY-MM-DD HH:mm:ss"},on:{"change":_vm.onChange}})],1),_c('div',{staticClass:"dlexitem"},[_c('span',[_vm._v("批阅状态")]),_c('a-select',{staticClass:"nput",attrs:{"mode":"multiple","placeholder":"请选择批阅状态"},model:{value:(_vm.queryParams.status),callback:function ($$v) {_vm.$set(_vm.queryParams, "status", $$v)},expression:"queryParams.status"}},[_c('a-select-option',{attrs:{"value":null}},[_vm._v(" 全部 ")]),_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 暂存 ")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v(" 未提交 ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" 已提交 ")]),_c('a-select-option',{attrs:{"value":5}},[_vm._v(" 已驳回 ")]),_c('a-select-option',{attrs:{"value":7}},[_vm._v(" 已审批 ")]),_c('a-select-option',{attrs:{"value":8}},[_vm._v(" 成绩发布")])],1)],1),_c('div',{staticClass:"dlexitem"},[_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")])],1)]):_vm._e(),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.answerId; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
        total: _vm.total,
        current: _vm.pageNum,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNum - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"historyStatus",fn:function(item, row){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.statuslist[row.status])+" ")])]}},{key:"operation",fn:function(item){return [_c('div',{staticClass:"btn_router_link"},[(item.status == 3)?_c('a',{on:{"click":function($event){return _vm.gohomeWorkDetail(item.answerId, 1)}}},[_vm._v("批阅")]):_c('a',{on:{"click":function($event){return _vm.gohomeWorkDetail(item.answerId, 2)}}},[_vm._v("查看作业")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }